import * as React from "react"
import HeroHeader from "../components/heroHeader";
import DealLink from "../components/deal-link"
import Features from "../components/features"

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby";

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {

  const Deals = edges
    .map(edge => <Grid item xs={12} sm={6} md={4} key={edge.node.id}><DealLink deal={edge.node} /></Grid>)
  return (
    <Layout>
      <Seo title="Home" />
      <HeroHeader />
      <Features />
      <Box sx={{ pl: 2, pr: 2, pb: 5, pt: 5 }}>
        <Typography variant="h5" component="h2" sx={{ pt: 2, pb: 1 }}>Recent Deals &darr;</Typography>
        <Grid container spacing={2}>
          {Deals}
        </Grid>
      </Box>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "deals" }}}
      limit: 6
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            thumbnail
            merchant
            price
            msrp
            average
            dealLink
          }
        }
      }
    }
  }
`