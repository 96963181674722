import React from "react"
import { Link } from "gatsby"

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { alpha } from '@mui/material/styles';

export default () => {
    const theme = useTheme();

    return (
        <Grid container spacing={2} sx={{ pt: 6, pb: 6, textAlign: 'center' }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant="h3" sx={{ fontFamily: 'Hind' }}>We Find Better Deals</Typography>
                <Typography variant="h6" sx={{ fontFamily: 'Hind' }}>Not just any sale makes the cut</Typography>
            </Grid>
            <Grid container item xs={12} sx={{ display: 'flex', '.column': { display: 'flex', flexDirection: 'column', alignItems: 'center', '.MuiAvatar-root': { mb: 3 } } }}>
                <Grid item xs={12} md={4} className="column">
                    <Avatar sx={{ width: 60, height: 60, backgroundColor: alpha(theme.palette.primary.light, .5) }}><StorefrontIcon /></Avatar>
                    <Typography variant="h6">Products people want</Typography>
                    <p>We hand select products to ensure that only deals for items that people want are included.</p>
                </Grid>
                <Grid item xs={12} md={4} className="column">
                    <Avatar sx={{ width: 60, height: 60, backgroundColor: alpha(theme.palette.primary.light, .5) }}><StarIcon /></Avatar>
                    <Typography variant="h6">High quality items</Typography>
                </Grid>
                <Grid item xs={12} md={4} className="column">
                    <Avatar sx={{ width: 60, height: 60, backgroundColor: alpha(theme.palette.primary.light, .5) }}><PriceCheckIcon /></Avatar>
                    <Typography variant="h6">Only the best price</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}